// Styles
import './Loader.scss';

const Loader = () => {
    return (
        <div className="loader">
            <svg width="30" height="30" viewBox="0 0 30 30">
                <circle fill="none" stroke="#000" cx="15" cy="15" r="14"></circle>
            </svg>
        </div>
    )
}

export default Loader;
