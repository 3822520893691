// React
import React from 'react';
import ReactDOM from 'react-dom/client';

// Internals
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Styles and fonts
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-200.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-200italic.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-300.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-300italic.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-500.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-500italic.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-600.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-600italic.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-700.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-700italic.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-800.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-800italic.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-900.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-900italic.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-italic.woff2';
import './assets/fonts/source_sans_3/source-sans-3-v9-latin-regular.woff2';
import './index.scss';

// Components
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
