// Styles and assets
import './Help.scss';
import helpImage01 from '../../assets/img/help_01.png';
import helpImage02 from '../../assets/img/help_02.png';
import helpImage03 from '../../assets/img/help_03.png';
import helpImage04 from '../../assets/img/help_04.png';
import helpImage05 from '../../assets/img/help_05.png';
import helpImage06 from '../../assets/img/help_06.png';
import helpImage07 from '../../assets/img/help_07.png';
import helpImage08 from '../../assets/img/help_08.png';

const Help = ({ onClose }) => {
    return (
        <div className="help">
            <div className="header">
                <img className="logo" src="/img/favicons/android-chrome-384x384.png" alt="Comune di Lodi" />
            </div>

            <div className="body">
                <h2 className="text-center">Benvenuto su Laus App!</h2>
                <h3 className="text-center">Comune di Lodi</h3>
                <p>
                    Laus.App è uno strumento messo a disposizione dall'Amministrazione comunale di Lodi per raccogliere suggerimenti e segnalazioni relativi alla mobilità cittadina, a supporto dei lavori del <b>Piano Urbano della Mobilità Sostenibile</b> (PUMS).
                </p>
                <p>
                    Le segnalazioni devono riguardare osservazioni su criticità o proposte di miglioramento della mobilità cittadina, che saranno valutati nell'ambito dei lavori del PUMS.
                </p>
                <p>
                    Si prega di non inserire segnalazioni su questioni inerenti i lavori pubblici (ad esempio la manutenzione delle strade, delle piste ciclabili o dei marciapiedi), che possono essere segnalate scrivendo una mail all'indirizzo <a href="mailto:urp@comune.lodi.it">urp@comune.lodi.it</a>
                </p>
                <p>Ecco una piccola guida su come puoi iniziare a inviare la tua prima segnalazione.</p>

                <div className="section">
                    <h2>1. Invio di una segnalazione</h2>

                    <p>Posiziona il mirino nel punto in cui vuoi segnalare qualcosa e clicca sul pulsante <span className="small-icon">+</span></p>
                    <p className="image">
                        <img src={ helpImage02 } alt="" />
                    </p>

                    <p>Dalla finestra di invio di una nuova segnalazione compila i campi necessari.</p>
                    <p>Scegli almeno una categoria</p>
                    <p className="image">
                        <img src={ helpImage03 } alt="" />
                    </p>

                    <p>Se vuoi descrivi con un breve testo la tua segnalazione</p>
                    <p className="image">
                        <img src={ helpImage04 } alt="" />
                    </p>

                    <p>Se vuoi inviaci delle foto di ciò che stai segnalando</p>
                    <p className="image">
                        <img src={ helpImage05 } alt="" />
                    </p>

                    <p>Se non vuoi che il tuo nome sia pubblico, seleziona questa casella</p>
                    <p className="image">
                        <img src={ helpImage06 } alt="" />
                    </p>

                    <p>Clicca sul pulsante INVIA e la tua segnalazione sarà presa in carico dai nostri moderatori!</p>
                </div>

                <div className="section">
                    <h2>2. Mappa delle segnalazioni</h2>
                    <p>Nella schermata principale della mappa puoi visualizzare le segnalazioni degli altri utenti.</p>
                    <p className="image">
                        <img src={ helpImage01 } alt="" />
                    </p>
                </div>

                <div className="section">
                    <h2>3. Lista delle segnalazioni</h2>
                    <p>Puoi consultare in ogni momento la lista delle tue segnalazioni, approvate, e in attesa di approvazione, consultando la voce di menù <b>Le mie segnalazioni</b></p>
                    <p className="image">
                        <img src={ helpImage07 } alt="" />
                    </p>

                    <p>Le segnalazioni non ancora approvate o rifiutate possono anche essere cancellate, qualora ti accorgessi di avere fatto qualche errore o hai cambiato idea!</p>
                    <p className="image">
                        <img src={ helpImage08 } alt="" />
                    </p>
                </div>
            </div>

            <div className="footer">
                <div className="buttons">
                    <div className="button primary" onClick={() => { onClose(false) }}>HO CAPITO</div>
                </div>
            </div>
        </div>
    )
}

export default Help;
