// React
import { useState } from 'react';
import { Link } from "react-router-dom";

// Components
import AlertDialog from '../../components/AlertDialog';

// Styles and Icons
import './Profile.scss';
import { ReactComponent as LifeRingIcon } from '../../assets/icons/lifering.svg'

const Profile = ({ user, setUser }) => {
    const [ profileError, setProfileError ] = useState(null);
    const [ passwordError, setPasswordError ] = useState(null);
    const [ isProfileDialogOpen, showProfileDialog ] = useState(false);
    const [ isPasswordDialogOpen, showPasswordDialog ] = useState(false);
    const [ profileData, setProfileData ] = useState({
        first_name: user.first_name,
        last_name: user.last_name
    });
    const [ passwordData, setPasswordData ] = useState({
        password: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    const onSaveProfile = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/user', {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            },
            body: JSON.stringify(profileData)
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'success') {
                showProfileDialog(true);
                setUser({ ...user, ...response.data.user })
            }
            else {
                setProfileError(response.message);
            }
        })
        .catch((err) => {
            setProfileError(err.message);
        });
    }

    const onSavePassword = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/user/password', {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            },
            body: JSON.stringify(passwordData)
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'success') {
                setPasswordData({
                    password: '',
                    newPassword: '',
                    confirmNewPassword: ''
                });
                showPasswordDialog(true);
                setUser({ ...user, ...response.data.user })
            }
            else {
                setPasswordError(response.message);
            }
        })
        .catch((err) => {
            setPasswordError(err.message);
        });
    }

    const SaveProfileButton = () => {
        if (profileData.first_name && profileData.last_name) {
            return <div className="button full-width primary" onClick={() => onSaveProfile()}>SALVA</div>
        }
        else {
            return <div className="button full-width seconday">SALVA</div>
        }
    }

    const SavePasswordButton = () => {
        if (passwordData.password && passwordData.newPassword && passwordData.confirmNewPassword) {
            return <div className="button full-width primary" onClick={() => onSavePassword()}>MODIFICA PASSWORD</div>
        }
        else {
            return <div className="button  full-width secondary">MODIFICA PASSWORD</div>
        }
    }

    return (
        <div className="page profile">
            <div className="body">
                <h1>Profilo utente</h1>

                <div className="form">
                    <div className="field">
                        <div className="name">Nome</div>
                        <div className="value">
                            <input type="text" value={ profileData.first_name } onChange={(e) => { setProfileError(null); setProfileData({ ...profileData, first_name: e.target.value }) }} />
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">Cognome</div>
                        <div className="value">
                            <input type="text" value={ profileData.last_name } onChange={(e) => { setProfileError(null); setProfileData({ ...profileData, last_name: e.target.value }) }}/>
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">Indirizzo email</div>
                        <div className="value">
                            <input name="email" type="text" value={ user.email } disabled />
                        </div>
                    </div>

                    { profileError &&
                        <div className="message">{ profileError }</div>
                    }

                    <div className="buttons">
                        <SaveProfileButton />
                    </div>
                </div>

                <div className="form" style={{ marginTop: 80 }}>
                    <div className="field">
                        <div className="name">Password attuale</div>
                        <div className="value">
                            <input type="password" value={ passwordData.password } onChange={(e) => { setPasswordError(null); setPasswordData({ ...passwordData, password: e.target.value }) }}/>
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">Nuova Password</div>
                        <div className="value">
                            <input type="password" value={ passwordData.newPassword } onChange={(e) => { setPasswordError(null); setPasswordData({ ...passwordData, newPassword: e.target.value }) }}/>
                        </div>
                    </div>

                    <div className="field">
                        <div className="name">Ripeti la nuova password</div>
                        <div className="value">
                            <input type="password" value={ passwordData.confirmNewPassword } onChange={(e) => { setPasswordError(null); setPasswordData({ ...passwordData, confirmNewPassword: e.target.value }) }}/>
                        </div>
                    </div>

                    { passwordError &&
                        <div className="message">{ passwordError }</div>
                    }

                    <div className="buttons">
                        <SavePasswordButton />
                    </div>

                    <div className="footer" style={{ marginTop: '90px' }}>
                        <div className="helper">
                            <div className="title">
                                <LifeRingIcon /> AIUTO
                            </div>
                            <p>
                                Vuoi cancellare il tuo account da Laus.App?<br />
                                Scrivi una email all'indirizzo <a href="mailto:info@laus.app">info@laus.app</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <AlertDialog onClose={() => showProfileDialog(false)} open={ isProfileDialogOpen }>
                <p>
                    Dati del profilo salvati correttamente!
                </p>
            </AlertDialog>

            <AlertDialog onClose={() => showPasswordDialog(false)} open={ isPasswordDialogOpen }>
                <p>
                    La tua password è stata modificata con successo!
                </p>
            </AlertDialog>
        </div>
    )
}

export default Profile;
