// React
import { useState, useEffect } from 'react';
import { BrowserRouter, Link } from "react-router-dom";
import Router from "./pages/router";

// Browser Detection
import { deviceDetect } from 'react-device-detect';

// IndexDB
import { get, set } from 'idb-keyval';

// Components
import Sidebar from './components/Sidebar';
import AlertDialog from './components/AlertDialog';
import ConfirmationDialog from './components/ConfirmationDialog';

// Styles and icons
import './App.scss';
import { ReactComponent as HamburgerIcon } from './assets/icons/sandwich.svg'
import bookmarkIcon from './assets/img/safari_bookmark_icon.png';
import safariIcon from './assets/img/safari_icon.png';
import iosTutorial01 from './assets/img/ios_01.jpg';
import iosTutorial02 from './assets/img/ios_02.jpg';

const App = () => {
    const [ isInstallationDialogOpen, showInstallationDialog ] = useState(null);
    const [ isiOSSafariInstallationDialogOpen, showiOSSafariInstallationDialog ] = useState(null);
    const [ isiOSNotSafariInstallationDialogOpen, showiOSNotSafariInstallationDialog ] = useState(null);
    const [ isAppInstalledDialogOpen, showAppInstalledDialog ] = useState(null);
    const [ isThanksDialogOpen, showThanksDialog ] = useState(null);
    const [ deviceId, setDeviceId ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ isSidebarOpen, openSidebar ] = useState(false);
    const [ deferredInstallationPrompt, setDeferredInstallatonPrompt ] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search)
    const queryParamDeviceId = queryParameters.get('id')
    const device = deviceDetect();

    //
    // Su iOS
    // + entro in Chrome: mi deve dire di andare su Safari
    // + entro in Safari: mi deve dire di aggiungere a Home
    // + entro da Home: non deve dire nulla e il bottone installa non c'Ã¨
    //
    // Su Android
    // + entro in Chrome: mi deve uscire il popup di installazione
    // + chiudo, se clicco di nuovo su installa il popup compare
    // + se installo, mi deve dire tutto ok e il bottone installa sparisce
    // + se entro da home non ci deve essere il bottone installa e non deve comparire il popup
    //
    // Su tutti
    // + se chiudo il popup e ricarico il sito, per 1 gg non deve ricomparire il popup
    //
    if (window.matchMedia('(display-mode: standalone)').matches === false && isInstallationDialogOpen === null) {
        switch (device.os) {
            case 'iOS':
                showInstallationDialog(true);
                break;

            case 'Android':
                window.addEventListener('beforeinstallprompt', (e) => {
                    e.preventDefault();
                    if (!isInstallationDialogOpen) {
                        showInstallationDialog(true);
                    }

                    if (deferredInstallationPrompt === null) {
                        setDeferredInstallatonPrompt(e);
                    }
                });

                window.addEventListener('appinstalled', (e) => {
                    showAppInstalledDialog(true);
                });
                break;

            default:
                break;

        }
    }

    useEffect(() => {
        if (deviceId === null) {
            get('device_id').then((value) => {
                let deviceId = 0;
                if (value) {
                    setDeviceId(value);
                    deviceId = value;
                }
                else if (queryParamDeviceId) {
                    setDeviceId(queryParamDeviceId);
                    deviceId = queryParamDeviceId;
                }
                else {
                    setDeviceId(0);
                }

                if (deviceId !== null && deviceId !== 0) {
                    fetch(process.env.REACT_APP_API_BASE_URL + '/device', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            uuid: deviceId
                        })
                    })
                    .then((response) => response.json())
                    .then((response) => {
                        set('device_id', response.data.device.uuid);
                        setDeviceId(response.data.device.uuid);
                        if (response.data.device.user) {
                            get('access_token').then((value) => {
                                let user = response.data.device.user;
                                if (value) {
                                    user.access_token = value;
                                }
                                setUser(user);
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
                }
            });
        }
    }, [ queryParamDeviceId, deferredInstallationPrompt, device, isInstallationDialogOpen, deviceId, user, setUser ]);

    useEffect(() => {
        get('end_pums_message').then((value) => {
            if (value === undefined) {
                showThanksDialog(true);
            }
        })
    } , [  ])

    const promptInstallation = () => {
        switch (device.os) {
            case 'iOS':
                const is_safari = !device.ua.match(/CriOS/) && !device.ua.match(/FxiOS/) && !device.ua.match(/OPiOS/) && !device.ua.match(/mercury/);
                if (is_safari) {
                    showiOSSafariInstallationDialog(true);
                }
                else {
                    showiOSNotSafariInstallationDialog(true)
                }
                break;

            case 'Android':
                deferredInstallationPrompt.prompt();

                deferredInstallationPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('installation accepted');
                    }
                    else {
                        console.log('installation rejected')
                    }
                });
                break;

            default:
                break;
        }
    }

    const closeThanksDialog = () => {
        set('end_pums_message', true);
        showThanksDialog(false)
    }

    if (deviceId !== null) {
        return (
            <BrowserRouter>
                <Router user={ user } setUser={ setUser } isSidebarOpen={ isSidebarOpen } deviceId={ deviceId } />

                <div className="square-button top-left bg-white logo">
                    <Link to="/">
                        <img className="logo" src="/img/favicons/android-chrome-384x384.png" alt="Comune di Lodi" />
                    </Link>
                </div>

                <div className="square-button top-right bg-white hamburger" onClick={() => { openSidebar(true) }}>
                    <HamburgerIcon />
                </div>

                <Sidebar
                    isSidebarOpen={ isSidebarOpen }
                    openSidebar={ openSidebar }
                    user={ user }
                    setUser= { setUser }
                    deviceId={ deviceId }
                />

                <ConfirmationDialog onClose={ () => showInstallationDialog(false) } open={ isInstallationDialogOpen } onYes={() => { promptInstallation() }}>
                    <p className="title">Vuoi installare Laus.App sul tuo telefono?</p>
                    <p>Clicca SI e procedi con l'installazione.</p>
                </ConfirmationDialog>

                <AlertDialog onClose={ () => showiOSSafariInstallationDialog(false) } open={ isiOSSafariInstallationDialogOpen }>
                    <p className="title">Installazione di Laus.App con Apple Safari</p>
                    <p>Se vuoi installare Laus.App sul tuo iPhone, fai click sul tasto <img src={ bookmarkIcon } alt="" /> come in figura:</p>
                    <p className="image">
                        <img src={ iosTutorial01 } alt="" />
                    </p>
                    <p>Poi scorri nel menù che compare e fai click sulla voce "Aggiungi alla schermata Home" come in figura:</p>
                    <p className="image">
                        <img src={ iosTutorial02 } alt="" />
                    </p>
                    <p>Al termine dell'installazione, potrai usare Laus.App sul tuo iPhone come tutte le altre applicazioni!</p>
                </AlertDialog>

                <AlertDialog onClose={ () => showiOSNotSafariInstallationDialog(false) } open={ isiOSNotSafariInstallationDialogOpen }>
                    <p className="title">Installazione di Laus.App su iPhone</p>
                    <p>Se vuoi installare Laus.App sul tuo iPhone, apri il sito https://laus.app con il browser Safari e segui la guida!</p>
                    <p className="image">
                        <img src={ safariIcon } style={{ width: '30%' }} alt="" />
                    </p>
                </AlertDialog>

                <AlertDialog onClose={ () => showAppInstalledDialog(false) } open={ isAppInstalledDialogOpen }>
                    <p className="title">Laus.App è stata installata sul tuo telefono!</p>
                    <p>Da ora puoi interagire con Laus.App come fai con tutte le altre applicazioni, facendo click sull'icona nella schermata principale del tuo telefono.</p>
                </AlertDialog>

                <AlertDialog onClose={ () => closeThanksDialog() } open={ isThanksDialogOpen }>
                    <h2 className="text-center">Benvenuto su Laus App!</h2>
                    <h3 className="text-center">Grazie!</h3>
                    <p>
                        Il periodo di raccolta delle segnalazioni per il <b>Piano Urbano di Mobilità Sostenibile</b> è terminato il 25 maggio 2024.
                    </p>
                    <p>
                        Le segnalazioni pervenute sulle proposte di miglioramento della mobilità cittadina saranno comunque registrate, ma non saranno valutate al fine dei lavori del PUMS.
                    </p>
                    <p>
                        Si ricorda che segnalazioni inerente i lavori pubblici (es. asfalti o buche) o criticità temporanee devono essere segnalate via email all'indirizzo <a href="mailto:urp@comune.lodi.it">urp@comune.lodi.it</a>, che provvederà a inoltrarle agli uffici competenti.
                    </p>
                    <p>
                        <b>Grazie ancora per la collaborazione.</b>
                    </p>
                    {/*
                    <p>
                        Laus.App è uno strumento messo a disposizione dall'Amministrazione comunale di Lodi per raccogliere suggerimenti e segnalazioni relativi alla mobilità cittadina, a supporto dei lavori del <b>Piano Urbano della Mobilità Sostenibile</b> (PUMS).
                    </p>
                    <p>
                        Le segnalazioni devono riguardare osservazioni su criticità o proposte di miglioramento della mobilità cittadina, che saranno valutati nell'ambito dei lavori del PUMS.
                    </p>
                    <p>
                        Si prega di non inserire segnalazioni su questioni inerenti i lavori pubblici (ad esempio la manutenzione delle strade, delle piste ciclabili o dei marciapiedi), che possono essere segnalate scrivendo una mail all'indirizzo <a href="mailto:urp@comune.lodi.it">urp@comune.lodi.it</a>
                    </p>
                    */}
                </AlertDialog>
            </BrowserRouter>
        );
    }
    else {
        <></>
    }
};

export default App;
