import { useEffect, useRef, useCallback } from "react";

const AlertDialog = ({ open, onClose, children, ...props }) => {
    const dialogRef = useRef(null);

    useEffect(() => {
        const { current: el } = dialogRef;
        if (open && !el.open) el.showModal();
    }, [ open ]);

    const onCancel = useCallback(
        (e) => {
            e.preventDefault();
            onClose();
        },
        [ onClose ]
    );

    const onClick = useCallback(
        ({ target }) => {
          const { current: el } = dialogRef;
          if (target === el) onClose();
        },
        [ onClose ]
    );

    const close = () => {
        const { current: el } = dialogRef;
        open = false;
        el.close();
    }

    return (
        <dialog
            ref={ dialogRef }
            onClose={ onClose }
            onCancel={ onCancel }
            onClick={ onClick }
        >
            { children }
            <div className="form">
                <div className="buttons">
                    <div className="button primary" onClick={() => { close(); }}>OK</div>
                </div>
            </div>
        </dialog>
    )
};

export default AlertDialog;
