// React
import { useState } from 'react';

// Styles and Assets
import './Password.scss';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg'

const Password = (props) => {
    const [ isPasswordVisible, showPassword ] = useState(false);

    return (
        <div className="password">
            <input name={ props.name } type={ isPasswordVisible ? 'text' : 'password' } onChange={ props.onChange }/>
            <div className="icon" onClick={ () => showPassword(!isPasswordVisible) }>
                { isPasswordVisible &&
                    <EyeSlashIcon />
                }
                { !isPasswordVisible &&
                    <EyeIcon />
                }
            </div>
        </div>
    )
}

export default Password;
