// React
import { useNavigate } from "react-router-dom";

// Styles and Assets
import './Login.scss';

// Components
import { default as LoginBox } from '../../components/Login';

const Login = ({ setUser, deviceId }) => {
    const navigate = useNavigate();

    const onLoginSuccess = (user) => {
        setUser(user);
        navigate('/');
    }

    return (
        <div className="page login">
            <div className="body">
                <LoginBox setUser={ onLoginSuccess } deviceId={ deviceId } />
            </div>
        </div>
    )
}

export default Login;
