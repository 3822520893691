// React
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// Components
import Password from '../../components/Password';
import Loader from '../../components/Loader';

// Styles and Assets
import './Register.scss';

const Register = () => {
    const navigate = useNavigate();
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);
    const [ isLoaderVisible, showLoader ] = useState(false);
    const [ data, setData ] = useState({
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        confirmPassword: null,
        acceptPrivacy: false,
        acceptTerms: false
    });

    const onRegister = () => {
        showLoader(true);
        fetch(process.env.REACT_APP_API_BASE_URL + '/user', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((response) => {
            showLoader(false);
            if (response.status === 'success') {
                setSuccess(true);
            }
            else {
                setError(response.message);
            }
        })
        .catch((err) => {
            console.error(err.message);
        });
    }

    const RegisterButton = () => {
        if (data.first_name && data.last_name && data.email && data.password && data.confirmPassword && data.acceptPrivacy && data.acceptTerms) {
            return <div className="button primary full-width" onClick={() => onRegister()}>REGISTRATI</div>
        }
        else {
            return <div className="button secondary full-width">REGISTRATI</div>
        }
    }

    return (
        <div className="page register">
            { !success &&
                <div className="body">
                    <h1>Registrati</h1>

                    <div className="form">
                        <div className="field">
                            <div className="name">Nome</div>
                            <div className="value">
                                <input name="first_name" type="text" onChange={(e) => { setError(null); setData({ ...data, first_name: e.target.value }) }} />
                            </div>
                        </div>

                        <div className="field">
                            <div className="name">Cognome</div>
                            <div className="value">
                                <input name="last_name" type="text" onChange={(e) => { setError(null); setData({ ...data, last_name: e.target.value }) }}/>
                            </div>
                        </div>

                        <div className="field">
                            <div className="name">Indirizzo email</div>
                            <div className="value">
                                <input name="email" type="text" onChange={(e) => { setError(null); setData({ ...data, email: e.target.value }) }} />
                            </div>
                        </div>

                        <div className="field">
                            <div className="name">Password</div>
                            <div className="value">
                                <Password name="password" onChange={(e) => { setError(null); setData({ ...data, password: e.target.value }) }} />
                            </div>
                        </div>

                        <div className="field">
                            <div className="name">Ripeti la password</div>
                            <div className="value">
                                <Password name="confirmPassword" onChange={(e) => { setError(null); setData({ ...data, confirmPassword: e.target.value }) }} />
                            </div>
                        </div>

                        <div className="field">
                            <div className="value">
                                <div className="checkbox" style={{ alignItems: 'start' }}>
                                    <div>
                                        <input type="checkbox" id="accept-privacy" onChange={(e) => { setError(null); setData({ ...data, acceptPrivacy: e.target.checked }) }} />
                                    </div>
                                    <label className="text-wrap" htmlFor="accept-privacy">Autorizzo il trattamento dei miei dati personali ai sensi del Dlgs 196 del 30 giugno 2003 e dell'art. 13 GDPR</label>
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <div className="value">
                                <div className="checkbox" style={{ alignItems: 'start' }}>
                                    <div>
                                        <input type="checkbox" id="accept-terms" onChange={(e) => { setError(null); setData({ ...data, acceptTerms: e.target.checked }) }} />
                                    </div>
                                    <label className="text-wrap" htmlFor="accept-terms">Ho letto e accetto i termini e le condizioni di utilizzo di Laus.App</label>
                                </div>
                            </div>
                        </div>

                        { error &&
                            <div className="message">{ error }</div>
                        }

                        <div className="buttons">
                            <RegisterButton />
                        </div>

                    </div>
                </div>
            }

            { success &&
                <div className="body">
                    <h1>Bravo!</h1>
                    <p>Ora controlla la tua email per confermare la tua registrazione e cominciare ad interagire con la Città di Lodi!</p>

                    <div className="form">
                        <div className="buttons">
                            <div className="button primary full-width">
                                <Link to="/">TORNA ALLA MAPPA</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }

            { isLoaderVisible &&
                <Loader />
            }
        </div>
    )
}

export default Register;
