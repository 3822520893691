// React
import { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster'

// Components
import Issue from '../../components/Issue';
import Login from '../../components/Login';
import AlertDialog from '../../components/AlertDialog';
import Loader from '../../components/Loader';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Textarea from '../../components/Textarea';

// Pages
import Help from '../../pages/Help';

// Styles and Assets
import './Home.scss';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { ReactComponent as QuestionIcon } from '../../assets/icons/help.svg'
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg'

const Home = ({ user, setUser, isSidebarOpen }) => {
    const [ isLoaderVisible, showLoader ] = useState(false);
    const [ displayAddress, setDisplayAddress ] = useState(null);
    const [ markers, updateMarkers ] = useState([]);
    const [ isIssueOpen, openIssue ] = useState(false);
    const [ isNewIssueOpen, openNewIssue ] = useState(false);
    const [ issue, setIssue ] = useState(null);
    const [ issuesCategories, setIssuesCategories ] = useState([]);
    const [ categoriesDisabled, disableCategories ] = useState(false);
    const [ mapFilters, setMapFilters ] = useState({
        category_id: 0,
        pums: false
    })
    const [ filtersPanelOpen, openFiltersPanel ] = useState(false);
    const [ isAlertDialogOpen, showAlertDialog ] = useState(false);
    const [ isHelpOpen, openHelp ] = useState(false);
    const [ isConfirmationDialogOpen, showConfirmationDialog ] = useState(false);
    const [ newIssue, setNewIssue ] = useState({
        lat: null,
        lon: null,
        text: null,
        categories: [],
        photos: [],
        is_public: true
    });
    let map = null;

    if (issuesCategories.length === 0) {
        fetch(process.env.REACT_APP_API_BASE_URL + '/issues/categories')
        .then((response) => response.json())
        .then((response) => {
            setIssuesCategories(response.data.categories);
        });
    }

    const loadMarkers = useCallback((payload, filters = {}) => {
        let headers = {
            "Content-Type": "application/json",
        };

        if (user) {
            headers['Authorization'] = 'Bearer ' + user.access_token;
        }

        payload['context'] = 'issues';
        payload = { ...payload, ...filters }

        fetch(process.env.REACT_APP_API_BASE_URL + '/map', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload)
        })
        .then((response) => response.json())
        .then((response) => {
            updateMarkers(response.data.issues);
        })
        .catch((err) => {
            console.log(err.message);
        });
    }, [ user ])

    useEffect(() => {
        if (user && map) {
            const bounds = map.getBounds();
            const coordinates = {
                min_lat: bounds.getSouth(),
                max_lat: bounds.getNorth(),
                min_lon: bounds.getWest(),
                max_lon: bounds.getEast()
            }

            loadMarkers(coordinates);
        }
    }, [ user, map, loadMarkers ]);

    const markerIcon = (marker) => {
        if (user && marker.user_id === user.id && marker.status === 'approved') {
            return new L.Icon({
                iconUrl: require("../../assets/icons/pin-green.svg").default,
                iconSize: [ 40, 47 ],
                iconAnchor: [ 20, 47 ]
            });
        }

        if (user && marker.user_id === user.id && marker.status === 'pending') {
            return new L.Icon({
                iconUrl: require("../../assets/icons/pin-yellow.svg").default,
                iconSize: [ 40, 47 ],
                iconAnchor: [ 20, 47 ]
            });
        }

        return new L.Icon({
            iconUrl: require("../../assets/icons/pin.svg").default,
            iconSize: [ 40, 47 ],
            iconAnchor: [ 20, 47 ]
        });
    }

    /*
    const createMarkerIcon = function (cluster: MarkerCluster) {
        return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: "custom-marker-cluster",
            iconSize: [ 40, 47 ]
        });
    };
    */

    const mapReady = (e) => {
        const map = e.target;
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        loadMarkers(coordinates);
    }

    const loadIssue = (issue_id) => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/issue/' + issue_id)
            .then((response) => response.json())
            .then((response) => {
                setIssue(response.data.issue);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const NewIssueControl = () => {
        map = useMap();
        return null
    }

    const onMarkerClick = (issue) => {
        setIssue(null);
        const latlng = [ issue.lat, issue.lon ];
        const x = map.latLngToContainerPoint(latlng).x;
        const y = map.latLngToContainerPoint(latlng).y;
        const h1 = map.getSize().y * 0.75;
        const h = map.getSize().y / 2;
        const offset = (h1 - h) + 108;
        const point = map.containerPointToLatLng([ x, y - offset ]);
        map.panTo(point);
        openIssue(true);
        loadIssue(issue.id);
    }

    const onCloseClick = () => {
        openIssue(false);
        openNewIssue(false);
        setIssue(null);
        openHelp(false);
    }

    const onPlusClick = () => {
        if (issue == null) {
            const center = map.getCenter();
            setNewIssue({ ...newIssue, lat: center.lat, lon: center.lng });

            fetch('https://nominatim.openstreetmap.org/reverse?lat=' + center.lat + '&lon=' + center.lng + '&format=json')
                .then((response) => response.json())
                .then((data) => {
                    let address = '';
                    if (data.address.road) {
                        address = data.address.road;
                    }
                    if (data.address.house_number) {
                        address += ', ' + data.address.house_number;
                    }
                    if (data.address.quarter) {
                        address += ', ' + data.address.quarter;
                    }
                    setDisplayAddress(address);
                });

            openNewIssue(true);
        }
    }

    const closeNewIssue = () => {
        resetNewIssue();
        setDisplayAddress(null);
        openNewIssue(false);
    }

    const resetNewIssue = () => {
        setNewIssue({
            lat: null,
            lon: null,
            text: null,
            categories: [],
            photos: [],
            is_public: true
        });
    }

    const sendNewIssue = () => {
        const formData = new FormData();

        formData.set('lat', newIssue.lat);
        formData.set('lon', newIssue.lon);
        formData.set('location_display_name', displayAddress);
        formData.set('text', newIssue.text);
        formData.set('is_public', newIssue.is_public);

        if (newIssue.categories.length > 0) {
            newIssue.categories.map((category_id, index) => {
                formData.append('categories[]', parseInt(category_id));
                return true;
            });
        }

        if (newIssue.photos.length > 0) {
            newIssue.photos.map((file, index) => {
                formData.append('photos[]', file.file);
                return true;
            });
        }

        showLoader(true);
        fetch(process.env.REACT_APP_API_BASE_URL + "/auth/issue", {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + user.access_token
            },
            body: formData
        })
        .then((response) => {
            showLoader(false);
            return response.json()
        })
        .then((response) => {
            if (response.status === 'success') {
                resetNewIssue();
                setDisplayAddress(null);
                openNewIssue(false);
                showAlertDialog(true);
            }
        });
    }

    const SendNewIssueButton = () => {
        if (newIssue.lat && newIssue.lon && newIssue.categories.length > 0 && newIssue.text) {
            return (
                <div className="button primary" onClick={() => sendNewIssue()}>INVIA</div>
            );
        }
        else {
            return (
                <div className="button bg-gray">INVIA</div>
            );
        }
    }

    const selectCategory = (checkbox) => {
        let categories = newIssue.categories;
        const category_id = parseInt(checkbox.getAttribute('name'));
        if (checkbox.checked) {
            categories.push(category_id);
        }
        else {
            categories = categories.filter(c => c !== category_id);
        }

        if (categories.length === 3) {
            disableCategories(true);
        }
        else {
            disableCategories(false);
        }

        setNewIssue({ ...newIssue, categories });
    }

    const addPhoto = (e) => {
        const input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                let photos = newIssue.photos;
                photos.push({
                    file: input.files[0],
                    data: e.target.result
                });
                setNewIssue({ ...newIssue, photos });
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    const removePhoto = () => {
        let photos = newIssue.photos;
        let newPhotos = photos.splice(isConfirmationDialogOpen, 1);
        setNewIssue({ ...newIssue, newPhotos });
    }

    const toggleFiltersPanel = () => {
        openFiltersPanel(!filtersPanelOpen);
    }

    const filterMap = (context, filter) => {
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        if (context === 'category') {
            let newFilters = { ...mapFilters, ...{ category_id: filter }};
            setMapFilters(newFilters);
            openFiltersPanel(false);
            loadMarkers(coordinates, newFilters)
        }

        if (context === 'pums') {
            let newFilters = { ...mapFilters, ...{ pums: filter }};
            setMapFilters(newFilters);
            openFiltersPanel(false);
            loadMarkers(coordinates, newFilters)
        }
    }

    return (
        <div className="home">
            { isIssueOpen &&
                <>
                    <div className="square-button top-left bg-yellow logo" style={{ zIndex: 1000001 }}>
                        <Link to="/">
                            <img className="logo" src="/img/favicons/android-chrome-384x384.png" alt="Comune di Lodi" />
                        </Link>
                    </div>

                    <div className={`square-button top-right ${isIssueOpen ? 'bg-yellow' : 'bg-white'} close`}>
                        <CloseIcon onClick={() => { onCloseClick() }} />
                    </div>
                </>
            }

            { (isNewIssueOpen || isHelpOpen) &&
                <div className={`square-button top-right ${isIssueOpen ? 'bg-yellow' : 'bg-white'} close`}>
                    <CloseIcon onClick={() => { onCloseClick() }} />
                </div>
            }

            { (!isIssueOpen && !isSidebarOpen) &&
                <>
                    { !filtersPanelOpen &&
                        <div className="square-button bottom-left bg-white plus">
                            <PlusIcon onClick={() => { onPlusClick() }} />
                        </div>
                    }

                    <div className={ filtersPanelOpen ? 'filters open' : 'filters' }>
                        { mapFilters.pums && false &&
                            <div className="pums-label">SEGNALAZIONI PUMS</div>
                        }
                        <div className="active" onClick={() => { toggleFiltersPanel() } }>
                            <div className="label">
                                { mapFilters.category_id ? issuesCategories.find(c => c.id === mapFilters.category_id).name : 'Tutte le categorie' }
                            </div>
                        </div>
                        <div className="list">
                            <div className="radio">
                                <input id="filter_category_0" type="radio" name="filter_category" checked={ mapFilters.category_id === 0 } onChange={() => filterMap('category', 0)} />
                                <label htmlFor="filter_category_0">Tutte le categorie</label>
                            </div>
                            { issuesCategories && issuesCategories.map((category, index) =>
                                <div key={ index } className="radio">
                                    <input
                                        id={ 'filter_category_' + category.id }
                                        type="radio"
                                        name="filter_category"
                                        onChange={() => filterMap('category', category.id) }
                                    />
                                    <label htmlFor={ 'filter_category_' + category.id }>{ category.name }</label>
                                </div>
                            )}
                        </div>
                        <div className="active">
                            <div className="label">Segnalazioni PUMS</div>
                        </div>
                        <div className="list">
                            <div className="radio">
                                <input id="filter_pums_yes" type="radio" name="filter_pums" checked={ mapFilters.pums === true } onChange={() => filterMap('pums', true)} />
                                <label htmlFor="filter_pums_yes">Si</label>
                            </div>
                            <div className="radio">
                                <input id="filter_pums_no" type="radio" name="filter_pums" checked={ mapFilters.pums === false } onChange={() => filterMap('pums', false)} />
                                <label htmlFor="filter_pums_no">No</label>
                            </div>
                        </div>
                    </div>

                    { !filtersPanelOpen &&
                        <div className="square-button bottom-right bg-white question" onClick={() => { openHelp(true) }}>
                            <QuestionIcon />
                        </div>
                    }
                </>
            }

            <div id="issue-wrapper" className={ isIssueOpen ? "open" : null }>
                <div className="topbar fixed bg-yellow">
                    <div className="pill">SEGNALAZIONE</div>
                </div>
                { issue &&
                    <Issue key={ issue.id } issue={ issue } />
                }
            </div>

            <div id="map-wrapper">
                <MapContainer center={[ 45.3135139, 9.5040738 ]} zoom={ 13 } scrollWheelZoom={ true } zoomControl={ false } whenReady={ mapReady }>
                    { issue == null &&
                        <div className="marker">
                            <div className="hr"></div>
                            <div className="vr"></div>
                            <div className="c"></div>
                        </div>
                    }

                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    <MarkerClusterGroup chunkedLoading>
                        { markers.map((marker, index) =>
                            <Marker
                                key={ `marker-${index}` }
                                position={[ marker.lat, marker.lon ]}
                                icon={ markerIcon(marker) }
                                eventHandlers={{ click: () => onMarkerClick(marker) }}
                            >
                            </Marker>
                        )}
                    </MarkerClusterGroup>

                    <NewIssueControl />
                </MapContainer>
            </div>

            <div id="newIssue-wrapper" className={ isNewIssueOpen ? "open" : null }>
                { (user?.access_token && newIssue.lat && newIssue.lon ) &&
                    <div className="page">
                        <div className="topbar fixed">
                            <div className="pill">NUOVA SEGNALAZIONE</div>
                        </div>

                        <div className="body">
                            <div className="form">
                                <p>
                                    Invia una nuova segnalazione a queste coordinate:
                                </p>

                                <div className="field">
                                    <div className="name">Indirizzo</div>
                                    <div className="value">{ displayAddress }</div>
                                </div>

                                <div className="field">
                                    <div className="name">Coordinate</div>
                                    <div className="value">
                                        <PinIcon /> { newIssue.lat.toFixed(10) }, { newIssue.lon.toFixed(10) }
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="name">Tipologia (Max. 3)</div>
                                    <div className="value">
                                        { issuesCategories && issuesCategories.map((category, index) =>
                                            <div key={ index } className="checkbox">
                                                <input
                                                    id={ 'category_' + category.id }
                                                    type="checkbox"
                                                    name={ category.id }
                                                    onChange={(e) => selectCategory(e.target)}
                                                    disabled={ categoriesDisabled && newIssue.categories.includes(category.id) === false }
                                                />
                                                <label htmlFor={ 'category_' + category.id }>{ category.name }</label>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="name">Testo della Segnalazione</div>
                                    <div className="value">
                                        <Textarea lang="it" name="text" rows="10" maxlength={ 300 } onChange={(e) => { setNewIssue({ ...newIssue, text: e.target.value }) }}></Textarea>
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="name">Foto (Massimo 3)</div>
                                    <div className="value">
                                        <div className="addPhoto">
                                            Aggiungi una foto
                                            <input name="photos[]" type="file" accept="image/x-png,image/jpeg,image/gif" onChange={(e) => addPhoto(e)} />
                                        </div>
                                        <div className="photos">
                                            { newIssue.photos && newIssue.photos.map((photo, index) =>
                                                <div key={ index } className="preview">
                                                    <img src={ photo.data } alt="" />
                                                    <div className="remove" onClick={(e) => { showConfirmationDialog(index) }}>
                                                        <TrashIcon />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="value">
                                        <div className="checkbox">
                                            <input type="checkbox" id="hide-name" onChange={(e) => { setNewIssue({ ...newIssue, is_public: (e.target.checked ? 0 : 1) }) }} />
                                            <label htmlFor="hide-name">Non mostrare il mio nome</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="buttons">
                                    <div className="button secondary" onClick={() => closeNewIssue()}>ANNULLA</div>
                                    <SendNewIssueButton />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                { (!user || !user.access_token) &&
                    <div className="page login">
                        <div className="body">
                            <Login setUser={ setUser }/>
                        </div>
                    </div>
                }
            </div>

            <AlertDialog onClose={() => showAlertDialog(false)} open={ isAlertDialogOpen }>
                <p>
                    Grazie per il tuo contributo!
                </p>
                <p>
                    La tua segnalazione verrà revisionata dai nostri operatori, e se rispetta i termini di utilizzo dell'applicazione, verrà pubblicata il prima possibile.
                </p>
            </AlertDialog>

            <div id="help-wrapper" className={ isHelpOpen ? "open" : null }>
                <div className="page">
                    <div className="topbar fixed">
                        <div className="pill">BENVENUTO</div>
                    </div>

                    <Help onClose={ openHelp } />
                </div>
            </div>

            <ConfirmationDialog onClose={() => showConfirmationDialog(false)} open={ isConfirmationDialogOpen !== false } onYes={ removePhoto }>
                <p>Vuoi rimuovere questa foto?</p>
            </ConfirmationDialog>

            { isLoaderVisible &&
                <Loader />
            }
        </div>
    );
};

export default Home;
