// React
import { useState } from 'react';

// Date-fns
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { it } from 'date-fns/locale';

// Styles and Assets
import './Issue.scss';
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg'
import { ReactComponent as PersonIcon } from '../../assets/icons/person.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'

const Issue = ({ issue, children }) => {
    const [ activeDot, setActiveDot ] = useState(0);

    const handleScroll = (e) => {
        let dotIndex = 0;
        dotIndex = Math.floor(e.target.scrollLeft / e.target.offsetWidth);
        if (activeDot !== dotIndex) {
            setActiveDot(dotIndex);
        }
    }

    return (
        <div className={ `issue ${issue.fullHeight ? 'full-height' : ''}`}>
            { issue.photos && issue.photos.length > 0 &&
                <div className="photos">
                    <div className="slider" onScroll={ handleScroll }>
                        { issue.photos.map((photo, index) =>
                            <div key={ photo.id } className="photo">
                                <img src={ photo.url } alt="" />
                            </div>
                        )}
                    </div>
                    { issue.photos.length > 1 &&
                        <div className="dots">
                            { issue.photos.map((photo, index) =>
                                <div key={ photo.id } className={ index === activeDot ? 'dot active' : 'dot' }></div>
                            )}
                        </div>
                    }
                </div>
            }

            <div className="body">
                { issue.is_public &&
                    <div className="user">
                        <PersonIcon /> { issue.user?.first_name } { issue.user?.last_name }
                    </div>
                }
                <div className="location">{ issue.location_display_name }</div>

                { issue.categories && issue.categories.length > 0 &&
                    <div className="categories">
                        { issue.categories.map((category, index) =>
                            <div key={ category.id } className="category">{ category.name }</div>
                        )}
                    </div>
                }

                <div className="coordinates">
                    <PinIcon /> { issue.lat.toFixed(10) }, { issue.lon.toFixed(10) }
                </div>

                <div className="date">
                    <CalendarIcon /> { format(parseISO(issue.created_at), 'd MMMM yyyy', { locale: it }) }
                </div>

                { issue.fullHeight &&
                    <div className="status">
                        <div className={ 'pill ' + issue.status }></div>
                    </div>
                }

                <div className="text">{ issue.text }</div>

                { children }
            </div>
        </div>
    )
}

export default Issue;
